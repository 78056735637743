
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';



@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})

export class ActivityComponent implements OnInit {


    constructor() { }

    ngOnInit() {

    }


}
