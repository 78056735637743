
import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { CalendarEvent,  } from 'angular-calendar';
import * as _ from 'lodash';

import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours
  } from 'date-fns';


  const colors: any = {
    red: {
      primary: '#ff0000',
      secondary: '#ff0000'
    },
    yellow: {
      primary: '#ffff00',
      secondary: '#ffff00'
    },
    green: {
      primary: '#33cc33',
      secondary: '#33cc33'
    },
    redblue: {
      primary: '#660000',
      secondary: '#660000'
    },
    yellowblue: {
      primary: '#b3b300',
      secondary: '#b3b300'
    },
    greenblue: {
      primary: '#196619',
      secondary: '#196619'
    },
  };

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})


export class CalendarComponent implements OnInit, OnChanges {
    
    @Input() viewDate?: Date;
    /*
     *data for config the button
    */
    @Input() eventsCalendar;
    /*
    * id button for emit
    */
    @Output() dayHandler = new EventEmitter();

   

    events: CalendarEvent[];

    activeDayIsOpen: boolean = false;

    locale: string = 'es';

  
    constructor() { }
    ngOnInit() {
      if (!this.viewDate) {
        this.viewDate = new Date();
      }
     
      this.parseEventsDataCalendar(this.eventsCalendar);
    }


    ngOnChanges(changes) {
      if (changes['viewDate']) {
        this.viewDate = changes['viewDate'].currentValue;
      }

      if (changes['eventsCalendar']){
        this.parseEventsDataCalendar(changes['eventsCalendar'].currentValue)
      }
    }
    nextMonth(){
      this.activeDayIsOpen = false;
      this.viewDate.setMonth(this.viewDate.getMonth() + 1);
      this.viewDate = _.cloneDeep(this.viewDate);
    }
    previusMonth(){
      this.activeDayIsOpen = false;
      this.viewDate.setMonth(this.viewDate.getMonth() - 1);
      this.viewDate = _.cloneDeep(this.viewDate);
    }
    actualMonth(){
      this.activeDayIsOpen = false;
      this.viewDate = new Date();
      this.viewDate = _.cloneDeep(this.viewDate);
    }
    /*
    * parse the events data
    */
    parseEventsDataCalendar(data){
     let events = [];
     if (data && data.length > 0){
          data.forEach(element => {
         let cacheItem =  {
              start: startOfDay(new Date(element.start)),
              title: element.title,
              color: this.getColorAux(element),
              data: element
          };

         events.push(cacheItem);
      });
      this.events = events;
     }

    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
      if (isSameMonth(date, this.viewDate)) {
        this.viewDate = date;
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
      }
      this.dayHandler.emit({
        date: date,
        events: events,
      });
    }

    getMonthName(month){
      if (month === 0){
        return 'Enero';
      }
      if (month === 1){
        return 'Febrero';
      }
      if (month === 2){
        return 'Marzo';
      }
      if (month === 3){
        return 'Abril';
      }
      if (month === 4){
        return 'Mayo';
      }
      if (month === 5){
        return 'Junio';
      }
      if (month === 6){
        return 'Julio';
      }
      if (month === 7){
        return 'Agosto';
      }
      if (month === 8){
        return 'Septiembre';
      }
      if (month === 9){
        return 'Octubre';
      }
      if (month === 10){
        return 'Noviembre';
      }
      if (month === 11){
        return 'Diciembre';
      }
    }
    private getColorAux(data) {
      return colors[data.color];
    }

    private getColor(data){
      
      let paramFull = false;
      if (data && data.sidrerias){
        data.sidrerias.forEach(el => {
          if (el.is_full){
            paramFull = true;
          }
        });

        if (data.closed){
          if (data.persons_limit !== null){
            return colors.redblue;
          } else {
            return colors.red;
          }
        }else{
          if (paramFull){

            if (data.persons_limit !== null){
              return colors.yellowblue;
            } else {
              return colors.yellow;
            }
          } else{
            if (data.persons_limit !== null){
              return colors.greenblue;
            } else {
              return colors.green;
            }
          }
        }
      }
    }

}
