// External Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Internal dependencies
import {
  AdminLang,
  GetAdminLangsRequest,
  GetAdminLangsResponse,
  DeleteAdminLangResponse,
  DeleteAdminLangRequest,
  EditAdminLangRequest,
  EditAdminLangResponse,
  AddAdminLangRequest,
  AddAdminLangResponse
} from "../interfaces/languages.interfaces";
import { ApiService } from './api.service';
import { ErrorResponse } from '../../shared/interfaces';

@Injectable()
export class LanguagesService {
  /**
   * Selected lang
   */
  private selected: AdminLang;

  /**
   * Selected operation add or edit
   */
  private operation: string;

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Service to get list of administration langs
   * @param params GetAdminLangsResponse
   * @returns Observable<GetAdminLangsResponse | ErrorResponse>
   */
  getInitialData(params?: GetAdminLangsRequest): Observable<GetAdminLangsResponse | ErrorResponse> {
    let path: string = 'api/admin/private/lang/';
    return this.apiService.get(path, params);
  }

  /**
   * Service to delete a administration lang
   * @param params DeleteAdminLangRequest
   * @returns Observable<DeleteAdminLangResponse | ErrorResponse>
   */
  delete(params: DeleteAdminLangRequest): Observable<DeleteAdminLangResponse | ErrorResponse> {
    let path: string = `api/admin/private/lang/${params.langId}`;
    return this.apiService.delete(path);
  }

  /**
   * Service to edit a administration lang
   * @param params EditAdminLangRequest
   * @returns Observable<EditAdminLangResponse | ErrorResponse>
   */
  edit(params: EditAdminLangRequest): Observable<EditAdminLangResponse | ErrorResponse> {
    let path: string = `api/admin/private/lang/${params.langId}`;
    delete params.langId;
    return this.apiService.put(path, params);
  }

  /**
   * Service to add a administration lang
   * @param params AddAdminLangRequest
   * @returns Observable<AddAdminLangResponse | ErrorResponse>
   */
  add(params: AddAdminLangRequest): Observable<AddAdminLangResponse | ErrorResponse> {
    let path: string = `api/admin/private/lang`;
    return this.apiService.post(path, params);
  }

  /**
   * Set selected item
   * @param data item selected
   * @returns void
   */
  setSelected(data: AdminLang): void {
    this.selected = data;
  }

  /**
   * Get selected item
   * @returns AdminLang
   */
  getSelected(): AdminLang {
    return this.selected;
  }


  /**
   * Set operation item
   * @param data item selected
   * @returns void
   */
  setOperation(data: string): void {
    this.operation = data;
  }

  /**
   * Get operation item
   * @returns string
   */
  getOperation(): string {
    return this.operation;
  }
}
