import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlimScrollDirective } from './slimscroll/slimscroll.directive';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SlimScrollDirective,
  ],
  exports: [
    SlimScrollDirective,
  ],
})
export class DirectivesModule {
  public static forRoot(): ModuleWithProviders {
    return { ngModule: DirectivesModule };
  }
}
