
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface IAlert {
    id: string;
    type: string;
    message: string;
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {
    /*
    * data for config the alert
    */
    @Input() dataAlert: IAlert;
    /*
    * data for config the alert
    */
    @Input() isOpen: boolean;
    /*
    * emit the alert id when click on close button
    */
    @Output() closeAlertHandler = new EventEmitter();

    constructor() { }
    ngOnInit() {
      
    }
    /*
    * emit the alert id when click on close button
    */
    closeAlert() {
        this.closeAlertHandler.emit(this.dataAlert.id);
    }

}
