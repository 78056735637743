
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-title',
    templateUrl: './tittle.component.html',
    styleUrls: ['./tittle.component.scss']
})

export class TittleComponent implements OnInit {

    @Input() title: string;

    @Input() subtitle: string;

    constructor() { }
    ngOnInit() {

    }

}
