import { Component, OnInit } from '@angular/core';
import { User } from '../../core/models/user.model';
import { StorageService } from '../../core/services/storage.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    public user: User;
    openModalLogout;
    constructor(
        private storageService: StorageService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.user = this.storageService.getCurrentUser();

    }

    logout() {
        this.openModalLogout = true;

    }
    confirmLogout(event) {
        if (event === 'continue') {
            this.storageService.removeCurrentSession();
            this.router.navigate(['login']);
        }
        this.openModalLogout = false;

    }
}
