import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {
  /*
   * Open the modal
   */
  @Input() set open(value) {
    if (value) {
      this.openModal();
    }

    this._open = value;
    // this.openChange.emit(this._open);

  }
  get open() {
    this.openChange.emit(this._open);
    return this._open;
  }
  _open = false;
  /*
   * title of the modal
   */
  @Input() title;
  /*
  * title of the modal
  */
  @Input() typeModal;
  /*
  * title of the modal
  */
  @Input() class = 'dark';
  /*
   * emit the open status
   */
  @Input() innerHTML;

  @Output() openChange = new EventEmitter();
  /*
   * emit hte value of button cliked
   */
  @Output() buttonClickedEmitter = new EventEmitter();
  /*
   * content of the modal
   */
  @ViewChild('content', { static: false }) content;

  constructor(private modalService: NgbModal) { }
  ngOnInit() {

  }
  /*
   * open the modal
   */
  openModal() {
    setTimeout(() => {
      let ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.modalService.open(this.content, ngbModalOptions);
    })
  }
  /*
   * close the modal
   */
  closeModal(value) {
    this.buttonClickedEmitter.emit(value);
    this.open = false;
  }

}
