import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';

import { FullLayoutComponent } from './shared/layouts/full/full-layout.component';
import { ContentLayoutComponent } from './shared/layouts/content/content-layout.component';
import { AuthGuard } from './core/guards/authorizated.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
