import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Cell } from '../../../lib/data-set/cell';

@Component({
  selector: 'table-cell-view-mode',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './view-cell.component.html',
  styleUrls: ['./view-cell.component.scss']
})
export class ViewCellComponent {
  @Input() cell: Cell;
  @Output() orderEmit = new EventEmitter();
  @Output() iconEmit = new EventEmitter();
  @Output() cellClicked = new EventEmitter();
  type = undefined;
  clickable = undefined;
  cellValue;
  labelColor;
  iconValue;
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    if (this.cell.getColumn()['settings']['path']) {
      let cellValue = this.convertPathToArrayOfItems(
        this.cell.getColumn()['settings']['path'].split('.'),
        this.cell['row']['data']
      );
      this.cell.setValue(cellValue);
      this.cell['value'] = cellValue;
    }
    this.type = this.cell.getColumn()['settings'].type;
    this.clickable = this.cell.getColumn()['settings'].clickable;

    if (this.cell.getColumn()['settings'].type === 'date') {
      this.cellValue = this.cell.getValue()
        ? moment(this.cell.getValue())
          .utc(true)
          .format('DD/MM/YYYY')
        : '--';
    }
    if (this.cell.getColumn()['settings'].type === 'customLabel') {
      this.cellValue = this.cell.getColumn()['settings'].condition(this.cell);
    }
    if (this.cell.getColumn()['settings'].type === 'specialLabel') {
      this.labelColor = this.cell
        .getColumn()
      ['settings'].condition(this.cell.getValue());
    }
    if (this.cell.getColumn()['settings'].type === 'specialLabelList') {
      this.labelColor = this.cell
        .getColumn()
      ['settings'].condition(this.cell.getValue());
    }
    if (this.cell.getColumn()['settings'].type === 'icon') {
      if (typeof this.cell.getColumn()['settings'].condition === 'function') {
        this.iconValue = this.cell.getColumn()['settings'].condition(this.cell);
      } else {
        this.iconValue = this.cell.getColumn()['settings'].icon;
      }
    }
    if (this.cell.getColumn()['settings'].type === 'specialLabelDate') {
      this.cellValue = moment(this.cell.getValue())
        .utc(true)
        .format('DD/MM/YYYY');
      this.labelColor = this.cell
        .getColumn()
      ['settings'].condition(this.cell.getValue());
    }
  }

  emitIconInfo() {
    this.iconEmit.emit(this.cell.getRow().getData());
  }

  emitClick() {
    this.cellClicked.emit({ column: this.cell.getColumn().id, data: this.cell.getRow().getData() });
  }

  emitOrder(event) {
    let data = {};
    if (this.cell.getColumn()['settings'].subtype === 'full') {
      if (event === 'down') {
        let cacheDown1 = this.cell['dataSet']['data'][
          this.cell.getRow()['index'] + 1
        ].order;
        let cacheDown2 = this.cell.getRow()['data'].order;
        this.cell.getRow()['data'].order = cacheDown1;
        this.cell['dataSet']['data'][
          this.cell.getRow()['index'] + 1
        ].order = cacheDown2;

        data = this.cell['dataSet']['data'];
      } else if (event === 'up') {
        let cacheDown1 = this.cell['dataSet']['data'][
          this.cell.getRow()['index'] - 1
        ].order;
        let cacheDown2 = this.cell.getRow()['data'].order;
        this.cell.getRow()['data'].order = cacheDown1;
        this.cell['dataSet']['data'][
          this.cell.getRow()['index'] - 1
        ].order = cacheDown2;

        data = this.cell['dataSet']['data'];
      }
    } else {
      if (event === 'down') {
        let cacheUp = {
          id: this.cell['dataSet']['data'][this.cell.getRow()['index'] + 1]._id,
          order: this.cell.getRow()['data'].order
        };
        let cacheDown = {
          id: this.cell.getRow()['data']._id,
          order: this.cell['dataSet']['data'][this.cell.getRow()['index'] + 1]
            .order
        };
        data = {
          up: cacheUp,
          down: cacheDown
        };
      } else if (event === 'up') {
        let cacheDown = {
          id: this.cell['dataSet']['data'][this.cell.getRow()['index'] - 1]._id,
          order: this.cell.getRow()['data'].order
        };
        let cacheUp = {
          id: this.cell.getRow()['data']._id,
          order: this.cell['dataSet']['data'][this.cell.getRow()['index'] - 1]
            .order
        };
        data = {
          up: cacheUp,
          down: cacheDown
        };
      }
    }

    this.orderEmit.emit(data);
  }

  /**
   * Recursive function that obtain the array of items.
   * param arrPath Path splited
   * param row Row to check the path.
   * returns {string |string[]} Return the list of items to show.
   */
  private convertPathToArrayOfItems(
    arrPath: string[],
    row: any
  ): string | string[] {
    let partOfItem: any = _.get(row, arrPath.shift(), undefined);
    if (Array.isArray(partOfItem)) {
      let arrReturn: string[] = [];
      partOfItem.forEach(arrItem => {
        if (arrPath.length === 0) {
          arrReturn.push(arrItem);
        } else {
          let returnedItem: string | string[] = this.convertPathToArrayOfItems(
            arrPath.slice(),
            arrItem
          );
          if (Array.isArray(returnedItem)) {
            console.warn(
              'COL-TEXT: Only support one array of objects in the paths.'
            );
          } else {
            arrReturn.push(returnedItem);
          }
        }
      });
      return arrReturn;
    } else {
      if (arrPath.length === 0) {
        return partOfItem;
      } else {
        return this.convertPathToArrayOfItems(arrPath, partOfItem);
      }
    }
  }
}
