
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';


export interface ICard {
    userId?: string;
    text: string;
    subtext?: string;
    link: string;
    icon: string;
    color: string;
    _id?: string;
}



@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss']
})

// tslint:disable-next-line:one-line
export class CardsComponent implements OnInit, OnChanges {
    /*
    *data for the shit
    */
    @Input() cards: ICard[];
    /*
    * emit the route for navigate
    */
    @Output() linkCardHandler = new EventEmitter();
    /*
    * emit the route for navigate
    */
    @Output() actionCardHandler = new EventEmitter();

    ngOnInit() {
        
    }

    ngOnChanges(changes) {
        
        if (changes['cards']) {
          this.cards =  changes['cards'].currentValue
        }
      }

    /*
    * emit the route for navigate
    */
    emitLink(card: ICard) {
        this.linkCardHandler.emit(card.link);
    }
    /*
    * emit the action for the card
    */
    emitAction(card: ICard) {
        this.actionCardHandler.emit(card);
    }
}
