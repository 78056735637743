
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../../core/services';
import { User } from '../../../core/models/user.model';
import * as _ from 'lodash';
export interface FileData {
  name: string;
  type: string;
  content: any;
  base?: any;
}

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})

export class FileComponent implements OnInit {

  @ViewChild('files', {static: false})
  myInputVariable: ElementRef;
  /*
  * images
  */
  @Input() set images(value) {
    this._images = value;
    this.AllFilesLoaded = [];
    this.showButtons = true;
  }
  get images() {
    return this._images;
  }

  /*
  * input for shot photo
  */
  @Input() showPhoto = true;
  /*
* indicate if is multiple
*/
  @Input() isMultiple = true;
  /*
   * input for emit file
   */
  @Output() fileEmitter = new EventEmitter();

  /*
 * files to upload
 */
  AllFilesLoaded: any = [];
  /*
  * User for the sesion
  */
  public user: User;

  public showButtons: boolean = true;

  private _images;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    // this.user = this.storageService.getCurrentUser();
  }
  /*
   * emit the icons selected
   */
  emitFile() {
    this.fileEmitter.emit(this.AllFilesLoaded);
    this._images = [];
    this.showButtons = false;
    // this.removeAll();
  }
  /*
   * emit the icons selected
   */
  remove(image) {
    this.myInputVariable.nativeElement.value = '';
    _.remove(this.AllFilesLoaded, (el) => {
      return el.name === image.name;
    });
  }
  /*
  * remove all the files selected
  */
  removeAll() {
    delete this.AllFilesLoaded;
    this.AllFilesLoaded = [];
  }
  /*
  * handle the files selected
  */
  handleFileInput(files: FileList) {
    Array.from(files).forEach(file => {
      this.getBase64(file);
    });

  }
  /*
  * get base64 foñe
  */
  getBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.convertFileToImage(reader.result, file);
      
    };
    reader.onerror = function (error) {

    };
  }
  /*
  * convert File to image
  */
  convertFileToImage(base, file) {
    
    let emitFile: FileData = {
      name: file.name,
      type: file.type.replace('image/', ''),
      content: base.split(',')[1],
      base: base,
    };
    this.AllFilesLoaded.push(emitFile);
  }
  
}
