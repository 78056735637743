
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})

export class SelectComponent implements OnInit {

  @Input() id: string;

  @Input() options: any;

  @Input() defaultValue: any;

  @Input() control: FormControl;


  optionsSelected = [];

  openModal = false;;

  constructor() { }

  ngOnInit() {

    this.control.valueChanges.subscribe(value => {
      //this.removeOption(value);
    });

  }

  selecteOption() {
    let find = _.find(this.options, (currentObject) => {
      return currentObject.id.toString() === this.control.value;
    });

    let findControl = _.find(this.optionsSelected, (currentObject) => {
      return currentObject.id.toString() === this.control.value;
    });
    if (!findControl) {
      this.optionsSelected.push(find);
    } else {
     this.openModal = true;
    }

  }

  private deleteOption(value) {

    let find = _.remove(this.optionsSelected, (currentObject) => {
      return currentObject.id === value;
    });

  }
}
