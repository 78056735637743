
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})

export class AccordionComponent implements OnInit {
    @Input() set open(value) {
        this._open = value;
    }
    get open() {
        this.openEmit.emit(this._open);
        return this._open;
    }
    _open = true;
    id =  Math.random().toString(36).slice(2);

    @Input() title: string;
    @Input() fixedTitle = true;
    @Output() openEmit = new EventEmitter();
    @Input() padding= true;
    ngOnInit() {
        
    }

    openAccordion() {
        this.open = !this.open;
    }
}
