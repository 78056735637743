import { AfterViewInit, Component, ViewContainerRef } from '@angular/core';
import { ToastsManager } from 'ng6-toastr';
import { BsLocaleService } from 'ngx-bootstrap';
import { defineLocale } from "ngx-bootstrap/chronos";
import { esLocale } from "ngx-bootstrap/locale";
import { LoaderService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  /**
   * manage the subscription of the loader
   */
  loaderShow: boolean = false;


  constructor(
    public toastr: ToastsManager,
    vRef: ViewContainerRef,
    private loader: LoaderService,
    private bsLocaleService: BsLocaleService
  ) {
    defineLocale("es", esLocale);
    this.bsLocaleService.use('es')
    this.toastr.setRootViewContainerRef(vRef);
  }

  ngAfterViewInit() {
    this.loader.getSubscription().subscribe((data) => {
      setTimeout(() => {
        this.loaderShow = data.show;
      }, 100);

    });
  }
}
