import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { BsLocaleService, defineLocale, esLocale } from 'ngx-bootstrap';

export interface IFiltersConfig {
  key: string;
  text: string;
  type: string;
  value: any;
  options?: any;
  enabled?: boolean,
}
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  /*
   * data for the details
   */
  @Input() config;
  /*
   * form
   */
  form;

  @Output() filtersHandler = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    private bsLocaleService: BsLocaleService,
  ) {
    defineLocale("es", esLocale);
    this.bsLocaleService.use('es');
  }

  ngOnInit() {
    this.createForm();
  }
  /*
   * create the form
   */
  createForm() {
    let fakeForm = {};
    this.config.forEach((el) => {
      if (el.value !== undefined) {
        fakeForm[el.key] = el.value;
        if (el.enabled !== undefined) {
          fakeForm['sw_' + el.key] = el.enabled;
        } else {
          fakeForm['sw_' + el.key] = false;
        }
      } 
  });
    this.form = this.formBuilder.group(fakeForm);
  }
  /*
   * handler the filters
   */
  submitFilter() {
    let cacheValue = {};
    _.keys(this.form.value).forEach((key: string) => {
      if (key.indexOf('sw_') !== -1 && this.form.value[key]) {
        cacheValue[_.last(key.split('sw_'))] =
          this.form.value[_.last(key.split('sw_'))];
      }
    });
    this.filtersHandler.emit(cacheValue);
  }

  clearFilter() {
    this.filtersHandler.emit({});
    this.config.forEach((el) => {
      if (el.value !== undefined) {
        this.form.get(el.key).value = false;
      } 
    });
    this.createForm();
  }
}
