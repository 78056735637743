
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoaderService } from './loader.service';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';

// GET BACKEND BASE PATH
const API_URL = environment.backend.path;
// URL FOR TESTING PURPOSES
// const API_URL = 'https://jsonplaceholder.typicode.com/';

@Injectable()
export class ApiService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');


  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private storageService: StorageService,
    private router: Router,
  ) { }

  private checkForError(response: HttpResponse<any>): HttpResponse<any> | Observable<any> {
    return response;
  }

  get(path: string, params?): Observable<any> {
    let reqOpts = {};
    let token = this.storageService.getCurrentToken();
    let headerSettings: { [name: string]: string | string[]; } = {};
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
      headerSettings['Content-Type'] = 'application/json ';
      let newHeader = new HttpHeaders(headerSettings);
      reqOpts['headers'] = newHeader;
    }
    if (params) {
      reqOpts['params'] = this.setQueryParams(params);
    }

    this.loader.show();
    return this.http.get(`${API_URL}${path}`, reqOpts).pipe(
      map(this.checkForError),
      catchError((err) => {
        if (err && err.error && err.error.code === 'AUTH_ERROR_TOKEN') {
          this.router.navigate(['login']);
        }
        return throwError(err);
      }),
      finalize(() => {
        this.loader.hide();
      })
    );
  }

  post(path: string, body): Observable<any> {
    let reqOpts = {};
    let token = this.storageService.getCurrentToken();
    let headerSettings: { [name: string]: string | string[]; } = {};
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
      headerSettings['Content-Type'] = 'application/json ';
      let newHeader = new HttpHeaders(headerSettings);
      reqOpts['headers'] = newHeader;
    } else {
      reqOpts['headers'] = { headers: this.headers };
    }

    this.loader.show();
    return this.http.post(
      `${API_URL}${path}`,
      JSON.stringify(body),
      token ? reqOpts : { headers: this.headers }
    ).pipe(
      map(this.checkForError),
      catchError((err) => {
        if (err && err.error && err.error.code === 'AUTH_ERROR_TOKEN') {
          this.router.navigate(['login']);
        }
        return throwError(err);
      }),
      finalize(() => {
        this.loader.hide();
      })
    );
  }

  put(path: string, body): Observable<any> {
    let reqOpts = {};
    let token = this.storageService.getCurrentToken();
    let headerSettings: { [name: string]: string | string[]; } = {};
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
      headerSettings['Content-Type'] = 'application/json ';
      let newHeader = new HttpHeaders(headerSettings);
      reqOpts['headers'] = newHeader;
    } else {
      reqOpts['headers'] = { headers: this.headers };
    }
    this.loader.show();
    return this.http.put(
      `${API_URL}${path}`,
      JSON.stringify(body),
      reqOpts
    ).pipe(
      map(this.checkForError),
      catchError((err) => {
        if (err && err.error && err.error.code === 'AUTH_ERROR_TOKEN') {
          this.router.navigate(['login']);
        }
        return throwError(err);
      }),
      finalize(() => {
        this.loader.hide();
      })
    );
  }

  delete(path): Observable<any> {
    let reqOpts = {};
    let token = this.storageService.getCurrentToken();
    let headerSettings: { [name: string]: string | string[]; } = {};
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
      headerSettings['Content-Type'] = 'application/json ';
      let newHeader = new HttpHeaders(headerSettings);
      reqOpts['headers'] = newHeader;
    } else {
      reqOpts['headers'] = { headers: this.headers };
    }
    this.loader.show();
    return this.http.delete(
      `${API_URL}${path}`,
      reqOpts
    ).pipe(
      map(this.checkForError),
      catchError((err) => {
        if (err && err.error && err.error.code === 'AUTH_ERROR_TOKEN') {
          this.router.navigate(['login']);
        }
        return throwError(err);
      }),
      finalize(() => {
        this.loader.hide();
      })
    );
  }

  private setQueryParams(params): HttpParams {
    let queryParams = new HttpParams();
    Object.keys(params).forEach(param => {
      queryParams = queryParams.append(param, params[param]);
    });
    return queryParams;
  }

  getPathParsed(path: string, param: string): string {
    return path + param;
  }


}
