import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'angular-calendar';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { PopoverModule } from 'ng4-popover';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { AccordionComponent } from './components/accordion/accordion.component';
import { ActivityComponent } from './components/activity/activity.component';
import { AlertComponent } from './components/alert/alert.component';
import { BlockComponent } from './components/block/block.component';
import { ButtonComponent } from './components/button/button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CardsComponent } from './components/cards/cards.component';
import { ColorComponent } from './components/color/color.component';
import { DetailsComponent } from './components/details/details.component';
import { ErrorComponent } from './components/error/error.component';
import { FileComponent } from './components/file/file.component';
import { FiltersComponent } from './components/filters/filters.component';
import { IconsComponent } from './components/icons/icons.component';
import { LeyendComponent } from './components/leyend/leyend.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { Ng2SmartTableModule } from './components/ng2-smart-table';
import { SelectComponent } from './components/select/select.component';
import { TableListComponent } from './components/table-list/tablelist.component';
import { TableComponent } from './components/table/table.component';
import { TareasComponent } from './components/tareas/tareas.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TittleComponent } from './components/tittle/tittle.component';
import { WidgetComponent } from './components/widget/widget.component';
import { DirectivesModule } from './directives/directives.module';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
@NgModule({
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    NgbModule,
    CardsComponent,
    WidgetComponent,
    AlertComponent,
    ButtonComponent,
    ModalComponent,
    TittleComponent,
    ColorComponent,
    IconsComponent,
    AccordionComponent,
    BlockComponent,
    TableListComponent,
    LeyendComponent,
    DetailsComponent,
    TableComponent,
    FileComponent,
    TareasComponent,
    ActivityComponent,
    TimelineComponent,
    LoaderComponent,
    SelectComponent,
    FiltersComponent,
    ErrorComponent,
    CalendarComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    BsDatepickerModule.forRoot(),
    PopoverModule,
    ReactiveFormsModule,
    DirectivesModule,
    AngularDateTimePickerModule,
    CalendarModule,
    BsDatepickerModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CardsComponent,
    WidgetComponent,
    AlertComponent,
    ButtonComponent,
    ModalComponent,
    TittleComponent,
    ColorComponent,
    IconsComponent,
    AccordionComponent,
    BlockComponent,
    TableListComponent,
    LeyendComponent,
    DetailsComponent,
    TableComponent,
    FileComponent,
    TareasComponent,
    ActivityComponent,
    TimelineComponent,
    LoaderComponent,
    SelectComponent,
    FiltersComponent,
    ErrorComponent,
    CalendarComponent
  ]
})
export class SharedModule { }
