import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface LoaderState {
  show: boolean;
}

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  cont = 0;
  constructor() { }

  show() {
    this.cont++;
    if (this.cont === 1) {
      this.loaderSubject.next(<LoaderState>{ show: true });
    }

  }

  hide() {
    this.cont--;
    if (this.cont < 0) {
      this.cont = 0;
    }
    if (this.cont === 0) {
      this.loaderSubject.next(<LoaderState>{ show: false });
    }
  }

  getSubscription() {
    return this.loaderSubject;
  }
}
