import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { Grid } from '../../../lib/grid';
import { DataSource } from '../../../lib/data-source/data-source';
import { Column } from "../../../lib/data-set/column";

@Component({
  selector: '[ng2-st-thead-titles-row]',
  template: `
  <!--
    <th ng2-st-checkbox-select-all *ngIf="isMultiSelectVisible"
                                   [grid]="grid"
                                   [source]="source"
                                   [isAllSelected]="isAllSelected"
                                   (click)="selectAllRows.emit($event)">
    </th>
    -->
<ng-container *ngIf="!defaultSettings.composeHeader">
    <th  ng2-st-actions-title *ngIf="showActionColumnLeft" [grid]="grid"></th>
    <ng-container *ngFor="let column of grid.getColumns()">
    <th  *ngIf="!column.settings.multiple" class="ng2-smart-th {{ column.id }}" [ngClass]="column.class"
      [style.width]="column.width" >
      <ng2-st-column-title [source]="source" [column]="column" (sort)="sort.emit($event)"></ng2-st-column-title>
    </th>
    </ng-container>
</ng-container>

<ng-container *ngIf="defaultSettings.composeHeader && !multi">
  <ng-container *ngFor="let column of grid.getColumns()">
  <th *ngIf="column.settings.multipleshow" class="ng2-smart-th {{ column.id }}" [ngClass]="column.class"
  [style.width]="column.width" >
  <ng2-st-column-title [source]="source" [column]="column" (sort)="sort.emit($event)"></ng2-st-column-title>
  </th>
  </ng-container>
</ng-container>


<ng-container *ngIf="defaultSettings.composeHeader && multi">
<th rowspan="2">Actions</th>
  <ng-container *ngFor="let column of grid.getColumns()">
    <th rowspan="2" *ngIf="!column['settings'].multipleshow" [ngClass]="column.class"
    [style.width]="column.width" >
      <ng2-st-column-title [source]="source" [column]="column" (sort)="sort.emit($event)">
      </ng2-st-column-title>
    </th>
    <th class="multiple-header"
    [style.width]="column.width" *ngIf="column['settings'].multiple && column['settings'].multipleshow" [colSpan]="column['settings'].colspan">{{column['settings'].multiple}}</th>
  </ng-container>
</ng-container>


    <!-- <th ng2-st-actions-title *ngIf="showActionColumnRight" [grid]="grid"></th> -->
  `,
})
export class TheadTitlesRowComponent implements OnChanges {

  @Input() grid: Grid;
  @Input() isAllSelected: boolean;
  @Input() source: DataSource;
  @Input() defaultSettings: any;
  @Input() multi: boolean = false;
  @Output() sort = new EventEmitter<any>();
  @Output() selectAllRows = new EventEmitter<any>();

  isMultiSelectVisible: boolean;
  showActionColumnLeft: boolean;
  showActionColumnRight: boolean;


  ngOnInit() {

  }


  ngOnChanges() {
    this.isMultiSelectVisible = this.grid.isMultiSelectVisible();
    this.showActionColumnLeft = this.grid.showActionColumn('left');
    this.showActionColumnRight = this.grid.showActionColumn('right');
  }

}
