// External Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Internal dependencies
import {
  GetAdminUserProfileTableRequest,
  GetAdminUserProfileTableResponse,
  EditAdminUserProfileTableRequest,
  EditAdminUserProfileTableResponse,
  ErrorResponse,
  AddAdminUserProfileTableResponse,
  AddAdminUserProfileTableRequest
} from "../../shared/interfaces";
import { ApiService } from "./api.service";

@Injectable()
export class ProfileService {
  
  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Service to get the configuration of the different tables of a especific page
   * @param params GetAdminUserProfileTableRequest
   * @returns Observable<GetAdminUserProfileTableResponse | ErrorResponse>
   */
  getPageTablesConfiguration(params?: GetAdminUserProfileTableRequest): Observable<GetAdminUserProfileTableResponse | ErrorResponse> {
    let path: string = `api/admin/private/user/profile/table`;
    return this.apiService.get(path, params);
  }
  
  /**
   * Service to edit the configuration of the tables of a especific page
   * @param params EditAdminUserProfileTableRequest
   * @returns Observable<EditAdminUserResponse | ErrorResponse>
   */
  editPageTablesConfiguration(params: EditAdminUserProfileTableRequest): Observable<EditAdminUserProfileTableResponse | ErrorResponse> {
    let path: string = `api/admin/private/user/profile/table/${params.userId}/${params.page_name}`;
    return this.apiService.put(path, { table: params.table });
  }

  /**
   * Service to add the configuration of the tables of a especific page
   * @param params AddAdminUserProfileTableRequest
   * @returns Observable<AddAdminUserProfileTableResponse | ErrorResponse>
   */
  addPageTablesConfiguration(params: AddAdminUserProfileTableRequest): Observable<AddAdminUserProfileTableResponse | ErrorResponse> {
    let path: string = `api/admin/private/user/profile/table`;
    return this.apiService.post(path, params);
  }

}
