import { Routes } from '@angular/router';

import { AuthGuard } from '../../core/guards/authorizated.guard';

export const Full_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('../../modules/dashboard/home/home.module').then(
        (m) => m.HomeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../../modules/dashboard/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/users',
    loadChildren: () =>
      import(
        '../../modules/administration/users/administration.users.module'
      ).then((m) => m.AdministrationUsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/languages',
    loadChildren: () =>
      import(
        '../../modules/administration/languages/administration.languages.module'
      ).then((m) => m.AdministrationLanguagesModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'administration/catalog',
    loadChildren: () =>
      import(
        '../../modules/administration/catalog/administration.catalog.module'
      ).then((m) => m.AdministrationCatalogModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/catalog-type',
    loadChildren: () =>
      import(
        '../../modules/administration/catalog-type/administration.catalog-type.module'
      ).then((m) => m.AdministrationCatalogTypeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/origins',
    loadChildren: () =>
      import(
        '../../modules/administration/origins/administration.origins.module'
      ).then((m) => m.AdministrationOriginsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/menu',
    loadChildren: () =>
      import(
        '../../modules/administration/menu/administration.menu.module'
      ).then((m) => m.AdministrationMenuModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/comments',
    loadChildren: () =>
      import(
        '../../modules/administration/comments/administration.comments.module'
      ).then((m) => m.AdministrationCommentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/season',
    loadChildren: () =>
      import(
        '../../modules/administration/season/administration.season.module'
      ).then((m) => m.AdministrationSeasonModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/filters',
    loadChildren: () =>
      import(
        '../../modules/administration/filters/administration.filters.module'
      ).then((m) => m.AdministrationFiltersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/activity',
    loadChildren: () =>
      import(
        '../../modules/administration/activity/administration.activity.module'
      ).then((m) => m.AdministrationActivityModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/products',
    loadChildren: () =>
      import(
        '../../modules/administration/products/administration.products.module'
      ).then((m) => m.AdministrationProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/traductions',
    loadChildren: () =>
      import(
        '../../modules/administration/traductions/administration.traductions.module'
      ).then((m) => m.AdministrationTraductionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'web/users',
    loadChildren: () =>
      import('../../modules/web/users/web.users.module').then(
        (m) => m.WebUsersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'web/facturation',
    loadChildren: () =>
      import('../../modules/web/facturation/web.facturation.module').then(
        (m) => m.WebFacturationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'web/filters',
    loadChildren: () =>
      import('../../modules/web/filters/web.filters.module').then(
        (m) => m.WebFiltersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/sidrerias',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/sidrerias/sagardobus.sidrerias.module'
      ).then((m) => m.SidreriasModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/itineraries',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/itineraries/sagardobus.itineraries.module'
      ).then((m) => m.SagardobusItinerariesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/discounts',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/discounts/sagardobus.discounts.module'
      ).then((m) => m.SagardobusDiscountsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/discounts-transaction',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/discounts-transaction/sagardobus.discounts-transaction.module'
      ).then((m) => m.SagardobusDiscountsTransactionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/calendar',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/calendar/sagardobus.calendar.module'
      ).then((m) => m.WebCalendarModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/passengers',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/passengers/sagardobus.passengers.module'
      ).then((m) => m.SagardobusPassengersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/bus-organization',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/bus-organization/sagardobus.bus-organization.module'
      ).then((m) => m.SagardobusBusOrganizationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/prices',
    loadChildren: () =>
      import('../../modules/sagardobus/prices/sagardobus.prices.module').then(
        (m) => m.SagardobusPricesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/booking',
    loadChildren: () =>
      import('../../modules/sagardobus/booking/sagardobus.booking.module').then(
        (m) => m.SagardobusBookingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/booking-temp',
    loadChildren: () =>
      import('../../modules/sagardobus/booking-temp/sagardobus.booking-temp.module').then(
        (m) => m.SagardobusBookingTempModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/tpv-transactions',
    loadChildren: () =>
      import('../../modules/sagardobus/tpv-transactions/sagardobus.tpv-transactions.module').then(
        (m) => m.SagardobusTpvTransactionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/booking-devolutions',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/booking-devolutions/sagardobus.booking-devolutions.module'
      ).then((m) => m.SagardobusBookingDevolutionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/booking-generation',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/booking-generation/sagardobus.booking-generation.module'
      ).then((m) => m.SagardobusBookingGenerationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/booking-grid',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/booking-grid/sagardobus.booking-grid.module'
      ).then((m) => m.SagardobusBookingGridModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardobus/statistics',
    loadChildren: () =>
      import(
        '../../modules/sagardobus/statistics/sagardobus.statistics.module'
      ).then((m) => m.SagardobusStatisticModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardoroute/resources',
    loadChildren: () =>
      import(
        '../../modules/sagardoroute/resources/sagardoroute.resources.module'
      ).then((m) => m.SagardorouteResourcesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardoroute/experiences',
    loadChildren: () =>
      import(
        '../../modules/sagardoroute/experiences/sagardoroute.experiences.module'
      ).then((m) => m.SagardorouteExperiencesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardoroute/calexperiences',
    loadChildren: () =>
      import(
        '../../modules/sagardoroute/calexperiences/sagardoroute.calexperiences.module'
      ).then((m) => m.SagardorouteCalExperiencesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sagardoroute/booking',
    loadChildren: () =>
      import(
        '../../modules/sagardoroute/booking/sagardoroute.booking.module'
      ).then((m) => m.SagardorouteBookingModule),
    canActivate: [AuthGuard],
  },
];
