// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  release: true,
  development: false,
  backend: {
    url: 'https://www.pre.sagardobus.com',
    port: '',
    path: '',
  },
};

// Define backend base url
environment.backend.path = environment.backend.url + '/';
