
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
    selector: 'app-leyend',
    templateUrl: './leyend.component.html',
    styleUrls: ['./leyend.component.scss']
})

export class LeyendComponent implements OnInit {
    /*
    * icon of the leyend
    */
    @Input() icon: string;


    constructor() { }
    ngOnInit() {

    }


}
