
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss']
})

export class ColorComponent implements OnInit {
  /*
   * output for color selected
   */
  @Output() colorSelectedHandler = new EventEmitter();
  /*
   * indicate the color selected
   */

  colors = [
    'linear-gradient(to bottom, #000066 0%, #0000cc 100%)',
    'linear-gradient(to bottom, #000000 0%, #222222 100%)',
    'linear-gradient(to bottom, #663300 0%, #996633 100%)',
    'linear-gradient(to bottom left, #003300 0%, #009900 100%)',
    'linear-gradient(to bottom left, #cc0099 0%, #ff33cc 100%)',
    'linear-gradient(to bottom left, #0000ff 0%, #33ccff 100%)',
    'linear-gradient(to bottom left, #990000 0%, #ff3300 100%)',
    'linear-gradient(to bottom left, #009900 0%, #33cc33 100%)',
    'linear-gradient(to bottom left, #660066 0%, #9900cc 100%)',
    'linear-gradient(to bottom left, #cc3300 0%, #ff9900 100%)',
    'linear-gradient(to bottom left, #009900 0%, #cccc00 100%)',
  ];

  selected = '#FF586B';
  constructor() { }

  ngOnInit() {
    this.colorSelectedHandler.emit('#FF586B');
  }
  /*
   * emit the color selected
   */
  emitColor(event) {
    this.selected = event;
    this.colorSelectedHandler.emit(event);
  }

}
