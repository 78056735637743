
import { Component, OnInit, Input } from '@angular/core';

export interface IDetails {
  title: string;
  text: any;
  items?: any;
  type?: string;
}

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit {
  /*
   * data for the details
   */
  @Input() data: IDetails;
  /*
   * data for the details
   */
  @Input() type: string = 'normal';

  constructor() { }

  ngOnInit() {
    
  }

}
