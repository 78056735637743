import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DataSource } from '../../lib/data-source/data-source';
import { Grid } from '../../lib/grid';

@Component({
  selector: '[ng2-st-tbody]',
  styleUrls: ['./tbody.component.scss'],
  templateUrl: './tbody.component.html',
})
export class Ng2SmartTableTbodyComponent {

  @Input() grid: Grid;
  @Input() source: DataSource;
  @Input() deleteConfirm: EventEmitter<any>;
  @Input() editConfirm: EventEmitter<any>;
  @Input() rowClassFunction: Function;

  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() custom = new EventEmitter<any>();
  @Output() edited = new EventEmitter<any>();
  @Output() userSelectRow = new EventEmitter<any>();
  @Output() editRowSelect = new EventEmitter<any>();
  @Output() multipleSelectRow = new EventEmitter<any>();
  @Output() rowHover = new EventEmitter<any>();
  @Output() orderEmit = new EventEmitter<any>();
  @Output() iconEmit = new EventEmitter<any>();
  @Output() cellClicked = new EventEmitter<any>();
  @Output() editHostias = new EventEmitter<any>();
  @Output() deleteHostias = new EventEmitter<any>();
  isMultiSelectVisible: boolean;
  showActionColumnLeft: boolean;
  showActionColumnRight: boolean;
  mode: string;
  editInputClass: string;
  isActionAdd: boolean;
  isActionEdit: boolean;
  isActionDelete: boolean;
  noDataMessage: boolean;

  ngOnChanges() {
    this.isMultiSelectVisible = this.grid.isMultiSelectVisible()
    this.showActionColumnLeft = this.grid.showActionColumn('left');
    this.mode = this.grid.getSetting('mode');
    this.editInputClass = this.grid.getSetting('edit.inputClass');
    this.showActionColumnRight = this.grid.showActionColumn('right');
    this.isActionAdd = this.grid.getSetting('actions.add');
    this.isActionEdit = this.grid.getSetting('actions.edit');
    this.isActionDelete = this.grid.getSetting('actions.delete');
    this.noDataMessage = this.grid.getSetting('noDataMessage');
  }

  orderEmitHandler(event) {
    this.orderEmit.emit(event);
  }
  iconEmitHandler(event) {
    this.iconEmit.emit(event);
  }
  cellClickedHandler(event) {
    this.cellClicked.emit(event);
  }
  editConfirmHostias(event) {
    this.editHostias.emit(event);
  }
  deleteConfirmHostias(event) {
    this.deleteHostias.emit(event);
  }
}
