
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
const BODY_CLASS_LOADER: string = 'have-loader';
const DEFAULT_TIMEOUT_LOADER: number = 60000; // 60s
const CLASS_FADE_IN: string = 'fade-in';
const CLASS_FADE_OUT: string = 'fade-out';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnDestroy {
  /**
   * Show the loader, also add class to content for the differents types of loader.
   * param  {boolean} setshowLoader(value)
   */
  @Input() set showLoader(value: boolean) {
    if (value) {

      this.timeoutId = setTimeout(() => {
        this._showLoader = value;
      }, 1);
      if (!this.relativeToParent) {
        document.body.classList.add(BODY_CLASS_LOADER);
      }
      this.fadeClass = CLASS_FADE_IN;
      this.timeoutId = setTimeout(() => {
        this.showLoader = false;
      }, this.timeout);
    } else {
      this.fadeClass = CLASS_FADE_OUT;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        document.body.classList.remove(BODY_CLASS_LOADER);
        this._showLoader = value;
        this.showLoaderChange.emit(value);
      }, 200);
    }
  }

  /**
   * The title of the loader
   */
  @Input() titleText: string;
  /**
   * Timeout for hide the loader.
   */
  @Input() timeout: number = DEFAULT_TIMEOUT_LOADER;
  /**
   * Set the loader to full screen if is false or relative to the parent if is true;
   */
  @Input() relativeToParent: boolean = false;
  /**
   * Double binding showLoader var.
   */
  @Output() showLoaderChange: EventEmitter<boolean> = new EventEmitter();
  /**
  * get the showLoader param
  * returns boolean
  */
  get showLoader(): boolean {
    return this._showLoader;
  }
  /**
  * class for fade-in or fade out
  */
  fadeClass: string = CLASS_FADE_IN;
  /**
   * indicate if the loader is show
   */
  private _showLoader;
  /**
   * the time for hide loader
   */
  private timeoutId: any;
  /**
   * Clear the variable and timeout
   */
  ngOnDestroy(): void {
    this.showLoader = false;
    clearTimeout(this.timeoutId);
  }
}
