// External Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Internal dependencies
import {
  GetEmailRegisterCompleteRequest,
  GetEmailRegisterCompleteResponse,
  ErrorResponse
} from "../../shared/interfaces";
import { ApiService } from "./api.service";

@Injectable()
export class EmailService {
  
  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Service to complete administration user register process
   * @param params GetEmailRegisterCompleteRequest
   * @returns Observable<GetEmailRegisterCompleteResponse | ErrorResponse>
   */
  completeRegister(params: GetEmailRegisterCompleteRequest): Observable<GetEmailRegisterCompleteResponse | ErrorResponse> {
    let path: string = `api/email/private/register/complete/${params.userId}`;
    return this.apiService.get(path);
  }

}
