// Angular
import { CommonModule, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeEs from "@angular/common/locales/es";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
registerLocaleData(localeEs);

// External modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { StorageServiceModule } from "angular-webstorage-service";
import { AngularDateTimePickerModule } from "angular2-datetimepicker";
import { AuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { ToastModule } from "ng6-toastr";

// Internal modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { APP_PROVIDERS } from "./app.providers";
import { getAuthServiceConfigs } from "./config";
import { CoreModule } from "./core/core.module";
import { JwtInterceptor } from "./core/interceptors/auth.interception";
import { ToastrService } from "./core/services/toastr.service";
import { ContentLayoutComponent } from "./shared/layouts/content/content-layout.component";
import { FullLayoutComponent } from "./shared/layouts/full/full-layout.component";
import { SharedModule } from "./shared/shared.module";


@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule.forRoot(),
    CommonModule,
    AngularDateTimePickerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    StorageServiceModule,
    SocialLoginModule
  ],
  providers: [
    FormBuilder,
    ToastrService,
    ...APP_PROVIDERS,
    { provide: LOCALE_ID, useValue: "es-ES" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
