
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { getDeepFromObject } from '../../components/ng2-smart-table/lib/helpers';
import { LocalDataSource } from '../ng2-smart-table';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, OnDestroy, OnChanges {
  /*
   * data for the details
   */
  // @Input() data;

  @Input() config;
  @Input() showTableButtons;
  @Input() tableName = '';
  @Input() searchValue = '';

  @Input() settings = undefined;
  @Input() defaultSettings;
  @Input() tableColumnConfig;
  @Input() set data(value: any) {
    this._items = value;
    this.source = _.cloneDeep(value);
  }
  get data(): any {
    return this._items;
  }

  @Output() custom = new EventEmitter<any>();
  @Output() tableColumnConfigHandler = new EventEmitter<any>();
  @Output() orderEmit = new EventEmitter<any>();
  @Output() iconEmit = new EventEmitter<any>();
  @Output() cellClicked = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() create = new EventEmitter<any>();
  _items;

  columns = [];

  source: LocalDataSource;
  /*
    * info cached
    */
  settingsClone;
  /*
    * info cached for multiple items
    */
  settingsCloneMultiple;

  /*
   * info for the columns
   */
  columnsInfo = [];


  sourceFinal;

  searchItems = [];
  controlHide = true;

  showColumns = false;

  tony = true;

  classTable = '';
  /*
   * Form
   */
  public hideForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.showTableButtons = (typeof this.showTableButtons !== 'undefined') ? this.showTableButtons : true;
    this.settingsClone = _.cloneDeep(this.settings);
    this.prepareHeaders();
    this.prepareSettings();
    this.prepareHideForm();

    if (this.defaultSettings && this.defaultSettings.composeHeader) {
      this.tripleHeader();
    }

    this.source = new LocalDataSource(this.data);
    this.sourceFinal = _.cloneDeep(this.source);
         
  }

  ngOnChanges(changes) {

    if (changes['data']) {
      this.data = changes['data'].currentValue
      this.source = new LocalDataSource(this.data);
      this.sourceFinal = _.cloneDeep(this.source);
      if (this.searchValue) {
        this.onSearch(this.searchValue);
      }
    }
  }

  ngOnDestroy() {
    this.settings = undefined;
    this.columns = undefined;
  }
  emitNumberRowsHandler(event) {
    this.defaultSettings['pager']['perPage'] = event;
    this.defaultSettings = _.cloneDeep(this.defaultSettings);
    this.tony = false;
    setTimeout(() => {
      this.tony = true;
    }, 10);
  }
  /**
   * Operative for prepare the triple header
   */
  tripleHeader() {
    Object.keys(this.settings.columns).forEach(key => {
      if (!this.settings.columns[key].title) {
        let cache = true;
        Object.keys(this.settings.columns[key]).forEach(keyChild => {
          this.settings.columns[keyChild] = this.settings.columns[key][keyChild];
          this.settings.columns[keyChild]['path'] = key + '.' + keyChild;
          this.columnsInfo.forEach(el => {
            if (el.multiple) {
              el.childs[keyChild] = keyChild;
            }
          });
          if (cache) {
            this.columnsInfo.forEach(el => {
              if (el.key === key) {
                el.multiple = true;
                el.childs = {};
                el.childs[keyChild] = keyChild;
              }
            });
            this.settings.columns[keyChild]['multipleshow'] = true;
            this.settings.columns[keyChild]['multiple'] = key;
            this.settings.columns[keyChild]['colspan'] = Object.keys(this.settings.columns[key]).length;
            cache = false;
          } else {
            this.settings.columns[keyChild]['multipleshow'] = true;
          }
        });
        delete this.settings.columns[key];
      }
    });
    this.settingsCloneMultiple = _.cloneDeep(this.settings.columns);
  }

  prepareSettings() {
    let tableConfig = _.head(this.tableColumnConfig);
    if (tableConfig) {
      let tableColumnItems = _.find(tableConfig.table, (item) => { return item._id === this.tableName });
      if (tableColumnItems) {
        tableColumnItems.columns.forEach((column: any) => {
          let columnItem = _.find(this.columnsInfo, (info) => { return info.key === column._id });
          if (columnItem) {
            columnItem.value = column.enabled;
          }
        });
        this.settings.columns = {};
        this.columnsInfo.forEach(key => {

          if (key.value) {
            if (key.multiple) {
              Object.keys(key.childs).forEach(el => {
                this.settings.columns[el] = this.settingsCloneMultiple[el];
              });

            } else {
              this.settings.columns[key.key] = this.settingsClone.columns[key.key];
            }
          }
        });

        this.settings = _.cloneDeep(this.settings);
      }
    }

  }

  /**
   * Emit the order
   */
  orderEmitHandler(event) {
    this.orderEmit.emit(event);
  }
  /**
  * Emit the order
  */
  cellClickedHandler(event) {
    this.cellClicked.emit(event);
  }
  /**
 * Emit the order
 */
  editer(event) {
    this.edit.emit(event);
  }
  /**
   * Emit the order
   */
  deleter(event) {
    this.delete.emit(event);
  }
  /**
 * Emit the order
 */
  creater(event) {
    this.create.emit(event);
  }
  /**
 * Emit the order
 */
  iconEmitHandler(event) {
    this.iconEmit.emit(event);
  }
  /**
   * Hide the columns
   */
  hideColumns() {
    this.showColumns = !this.showColumns;
  }
  /**
   * Operative for prepare the triple header
   */
  prepareHeaders() {
    this.columns = Object.keys(this.settings.columns);
    this.columns.forEach(key => {
      this.columnsInfo.push({ key: key, value: true });
    });
  }
  prepareHideForm() {

    this.tableColumnConfig = _.head(this.tableColumnConfig);

    this.hideForm = this.formBuilder.group({
      none: false,
      search: this.searchValue || ''
    });
    this.columns.forEach(el => {
      let cacheControl = new FormControl(true);
      this.hideForm.addControl(el + '_hide', cacheControl);
    });

    if (this.tableColumnConfig) {
      let tableColumnItems = _.find(this.tableColumnConfig.table, (item) => { return item._id === this.tableName });
      if (tableColumnItems) {
        tableColumnItems.columns.forEach((column: any) => {
          this.hideForm.controls[column._id + '_hide'].setValue(column.enabled);
        });
      }
    }

  }

  checkboxClicked(col: string) {
    if (this.controlHide) {
      this.settings.columns = {};
      //  this.columnsInfo[col] = !this.columnsInfo[col];
      this.columnsInfo.forEach(key => {
        if (key.key === col) {
          key.value = !key.value;
        }
        if (key.value) {
          if (key.multiple) {
            Object.keys(key.childs).forEach(el => {
              this.settings.columns[el] = this.settingsCloneMultiple[el];
            });

          } else {
            this.settings.columns[key.key] = this.settingsClone.columns[key.key];
          }
        }
      });
      setTimeout(() => {
        this.controlHide = true;
      }, 2);
    }
    this.controlHide = false;
    this.settings = _.cloneDeep(this.settings);
  }
  customEmitter(event) {
    this.custom.emit(event);
  }

  onSearch(query: string = '') {

    if (query === '') {
      let cache = _.cloneDeep(this.sourceFinal);
      this.source = undefined;
      this.source = cache;
    } else {
      this.columns = Object.keys(this.settings.columns);
      this.columns.forEach(key => {
        if (this.columns[key] && this.columns[key].noSearch) {

        } else {
          this.searchItems.push({ field: key, search: query, path: this.settings.columns[key].path });
        }
      });
      this.source.setFilter(this.searchItems, false);
    }
    this.custom.emit({ action: 'search', data: query});
  }

  saveColumns(event) {

    let formData = this.hideForm.getRawValue();
    let order = 1;
    let columns = [];

    _.keys(formData).forEach((key: string) => {
      if (key.indexOf('_hide') !== -1) {
        columns.push({
          _id: _.head(key.split('_hide')),
          enabled: formData[key],
          order: order
        });
        order = order + 1;
      }
    });

    let tableColumnConfigCurrent = _.cloneDeep(this.tableColumnConfig);

    if (tableColumnConfigCurrent) {
      let tableColumnItems = _.find(tableColumnConfigCurrent.table, (item) => { return item._id === this.tableName });
      if (tableColumnItems) {
        tableColumnItems.columns = columns;
      } else {
        tableColumnConfigCurrent.table.push({
          _id: this.tableName,
          columns: columns
        });
      }
    } else {
      tableColumnConfigCurrent = {};
      tableColumnConfigCurrent.table = [];
      tableColumnConfigCurrent.table.push({
        _id: this.tableName,
        columns: columns
      });
    }

    this.tableColumnConfigHandler.emit({ prev: (!_.isUndefined(this.tableColumnConfig)) ? this.tableColumnConfig.table : undefined, current: tableColumnConfigCurrent.table });
    this.hideColumns();
  }
  /**
 * generate Excel
 */
  generateExcel() {

    let data = [];
    let header = [];
    let columns = _.keys(this.settings.columns);
    columns.forEach(key => {
      header.push(this.settings.columns[key].title);
    });
    data.push(header);

    this.data.forEach(book => {
      let bookData = [];
      columns.forEach(key => {
        let column = this.settings.columns[key];
        let value = "";
        if (_.isUndefined(column.path)) {
          value = book[key];
        }
        else {
          value = getDeepFromObject(book, column.path);
        }
        if (column.type === "date" || column.type === "specialLabelDate") {
          value = moment(value).utc(true).format("DD/MM/YYYY");
        }
        if (Array.isArray(value)) {
          let valueStr = "";
          value.forEach(value => {
            valueStr += value.text + ",";
          });
          value = valueStr.substring(0, valueStr.length - 1);
        }
        bookData.push(value);
      });
      data.push(bookData);
    });

    let wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Tabla");
    XLSX.writeFile(wb, "Tabla.xlsx");
  }


  responsiveTable() {
    if (this.classTable === '') {
      this.classTable = 'table-responsive';
    } else {
      this.classTable = '';
    }
  }
}
