
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-block',
    templateUrl: './block.component.html',
    styleUrls: ['./block.component.scss']
})

export class BlockComponent implements OnInit {

    @Input() title: string;
    @Input() size = 'full';

    constructor() { }
    ngOnInit() {
      
    }


}
