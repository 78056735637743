
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-table-list',
    templateUrl: './tablelist.component.html',
    styleUrls: ['./tablelist.component.scss']
})

export class TableListComponent implements OnInit {

    @Input() headers: string[];

    @Input() data;

    constructor() { }
    ngOnInit() {
    }

}
