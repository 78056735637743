import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../core/models/user.model';
import { StorageService } from '../../core/services/storage.service';
import { DEFAULT_OPTIONS_DARK_SCROLL, ISlimScrollOptions } from './../directives/slimscroll';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';


declare var $: any;
@Component({
  // moduleId: module.id,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  /*
  * emit the alert id when click on close button
  */
  @Output() classSidebar = new EventEmitter();
  public menuItems: any[];
  optionsBar: ISlimScrollOptions;
  currentClass = '';
  activeMenu = 'nabo';
  user: User;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    ) {
  }

  ngOnInit() {
    this.user = this.storageService.getCurrentUser();
    this.optionsBar = DEFAULT_OPTIONS_DARK_SCROLL;
    $.getScript('./assets/js/app-sidebar.js');
    this.menuItems = ROUTES.filter((item) => {
      return item.roles.indexOf(this.user.role) !== -1 || item.roles.indexOf('ALL') !== -1
    });
    this.menuItems.forEach((el: RouteInfo) => {
      if (el.submenu.length > 0) {
        el.submenu = el.submenu.filter((submenuItem: RouteInfo) => {
          return submenuItem.roles.indexOf(this.user.role) !== -1 || submenuItem.roles.indexOf('ALL') !== -1
        });
      }
    })
  }

  emitSidebar() {
    if (this.currentClass === '') {
      this.currentClass = 'nav-collapsed menu-collapsed';
    } else {
      this.currentClass = '';
    }
    this.classSidebar.emit(this.currentClass);
  }

  activeItem(menuItem) {
    this.activeMenu = menuItem.title;
  }
}
