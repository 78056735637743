import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Row } from '../../../lib/data-set/row';

import { Grid } from '../../../lib/grid';

@Component({
  selector: 'ng2-st-tbody-custom',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

  <ng-template></ng-template>
      <a *ngFor="let action of grid.getSetting('actions.custom')" href="#"
         class="ng2-smart-action ng2-smart-action-custom-custom"
         [innerHTML]="action.title"
         [ngClass]="{'disable': ( (action.disable) &&  action.disable(row.getData())) }"
         (click)="onCustom(action, $event)"></a>


        `
})
export class TbodyCustomComponent implements OnInit {

  @Input() grid: Grid;
  @Input() row: Row;
  @Input() source: any;
  @Output() custom = new EventEmitter<any>();

  ngOnInit() {

  }

  onCustom(action: any, event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.custom.emit({
      action: action.name,
      data: this.row.getData(),
      source: this.source
    });
  }

}
