import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/home',
    title: 'Inicio',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '',
    title: 'SAGARDOBUS',
    icon: 'ft-square',
    class: '',
    badge: '',
    badgeClass: 'chocheta',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '#',
    title: 'Estadisticas',
    icon: 'ft-align-left',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['ALL'],
    submenu: [
      {
        path: '/sagardobus/statistics/statistics',
        title: 'Estadísticas Anuales',
        icon: 'icon-bar-chart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/statistics/statistics-global',
        title: 'Estadísticas Globales',
        icon: 'icon-bar-chart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
    ],
  },
  
  {
    path: '/sagardobus/sidrerias/sidrerias',
    title: 'Sidrerias',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/sagardobus/itineraries/itineraries',
    title: 'Itinerarios',
    icon: 'icon-graph',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '#',
    title: 'Descuentos',
    icon: 'ft-align-left',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['ALL'],
    submenu: [
      {
        path: '/sagardobus/discounts/discounts',
        title: 'Descuentos',
        icon: 'icon-energy',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/discounts-transaction/discounts-transaction',
        title: 'Transacciones Descuentos',
        icon: 'icon-energy',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
    ],
  },
  {
    path: '/sagardobus/calendar/calendar',
    title: 'Calendario',
    icon: 'icon-calendar',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/sagardobus/prices/prices',
    title: 'Precios',
    icon: 'icon-wallet',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '#',
    title: 'Reservas',
    icon: 'ft-align-left',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['ALL'],
    submenu: [
      {
        path: '/sagardobus/booking/booking',
        title: 'Listado Reservas',
        icon: 'icon-list',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/booking-temp/booking-temp',
        title: 'Listado Reservas Temp',
        icon: 'icon-camera',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/passengers/passengers',
        title: 'Listado Pasajeros',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/tpv-transactions/tpv-transactions',
        title: 'Transacciones Tpv',
        icon: 'icon-camera',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/booking/booking-summary',
        title: 'Resumen Reservas Sidreria',
        icon: 'icon-envelope',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/booking-devolutions/booking-devolutions',
        title: 'Devoluciones pendientes',
        icon: 'icon-action-undo',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/booking-generation/booking-generation',
        title: 'Generación de Reservas',
        icon: 'icon-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/booking-grid/booking-grid',
        title: 'Cuadro de Reservas',
        icon: 'icon-grid',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/sagardobus/bus-organization/bus-organization',
        title: 'Organización de Buses',
        icon: 'icon-directions',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
    ],
  },
  {
    path: '',
    title: 'WEB',
    icon: 'ft-square',
    class: '',
    badge: '',
    badgeClass: 'chocheta',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/web/users/users',
    title: 'Usuarios web',
    icon: 'icon-user',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/web/facturation/facturation',
    title: 'Facturación web',
    icon: 'icon-credit-card',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/web/filters/filters',
    title: 'Filtros ',
    icon: 'ft-filter',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '',
    title: 'ADMINISTRACIÓN',
    icon: 'ft-square',
    class: '',
    badge: '',
    badgeClass: 'chocheta',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/users/users',
    title: 'Usuarios',
    icon: 'ft-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ADMIN'],
  },
  {
    path: '/administration/products/products',
    title: 'Productos',
    icon: 'ft-inbox',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/origins/origins',
    title: 'Origenes',
    icon: 'icon-pointer',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '#',
    title: 'Catalogo',
    icon: 'icon-game-controller',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['ALL'],
    submenu: [
      {
        path: '/administration/catalog/catalog',
        title: 'Elementos',
        icon: 'icon-envelope',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
      {
        path: '/administration/catalog-type/catalog-type',
        title: 'Tipos',
        icon: 'icon-envelope',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['ALL'],
      },
    ],
  },
  {
    path: '/administration/languages/languages',
    title: 'Idiomas',
    icon: 'icon-speech',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/traductions/traductions',
    title: 'Traducciones',
    icon: 'icon-globe-alt',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/menu/menu',
    title: 'Menu',
    icon: 'ft-align-justify',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/comments/comments',
    title: 'Comentarios',
    icon: 'icon-envelope-letter',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/season/season',
    title: 'Temporadas',
    icon: 'icon-screen-tablet',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/filters/filters',
    title: 'Filtros',
    icon: 'ft-filter',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
  {
    path: '/administration/activity/activity',
    title: 'Actividad/Logs',
    icon: 'icon-pointer',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['ALL'],
  },
];
