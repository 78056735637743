import { Injectable } from '@angular/core';

@Injectable()
export class SharedDataService {
  private sharedData: any = {};

  constructor() {}

  setData(data: Object) {
    this.sharedData = Object.assign({}, this.sharedData, data);
    localStorage.setItem('sharedData', JSON.stringify(this.sharedData));
  }

  getAllData() {
    return Object.assign({}, this.sharedData, JSON.parse(localStorage.getItem('sharedData')));
  }

  getData(key: string) {
    this.sharedData = this.getAllData();
    return this.sharedData[key] ? this.sharedData[key] : console.error('NO DATA WITH THIS KEY: ', key);
  }

  clearData(key: string) {
    if (this.sharedData[key]) {
      delete this.sharedData[key];
      this.setData(this.sharedData);
    }
  }

  clearAllData() {
    localStorage.removeItem('sharedData');
  }

}
