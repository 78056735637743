import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent{
  currentClass;
  height = screen.height - 100 + 'px';


  classHandler(event) {
    this.currentClass = event;
  }
}
