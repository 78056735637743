// External Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Internal dependencies
import {
  GetCatalogItemRequest,
  GetCatalogItemResponse,
  ErrorResponse,
  GetCatalogTypeRequest,
  GetCatalogTypeResponse,
  CatalogItem,
  DeleteCatalogItemRequest,
  DeleteCatalogItemResponse,
  EditCatalogItemRequest,
  AddCatalogItemRequest,
  AddServiceStandarResponse,
  EditServiceStandarResponse,
  CatalogItemType,
  DeleteCatalogItemTypeRequest,
  DeleteCatalogItemTypeResponse,
  AddCatalogItemTypeRequest,
  EditCatalogItemTypeRequest,
} from '../../shared/interfaces';
import { ApiService } from './api.service';

@Injectable()
export class CatalogService {
  constructor(private apiService: ApiService) {}

  /**
   * Selected catalog item
   */
  private selected: CatalogItem;

  /**
   * Selected catalog item type
   */
  private selectedType: CatalogItemType;

  /**
   * Selected operation add or edit
   */
  private operation: string;

  /**
   * Dropdowns items
   */
  private dropdowns: any = {};

  /**
   * Service to get catalog item list
   * @param params GetCatalogItemRequest
   * @returns Observable<GetCatalogItemResponse | ErrorResponse>
   */
  getCatalogItems(
    params: GetCatalogItemRequest
  ): Observable<GetCatalogItemResponse | ErrorResponse> {
    let path: string = `api/catalog/private/item`;
    return this.apiService.get(path, params);
  }

  /**
   * Service to get catalog item type list
   * @param params GetCatalogItemTypeRequest
   * @returns Observable<GetCatalogItemTypeResponse | ErrorResponse>
   */
  getCatalogItemsTypes(
    params: GetCatalogItemRequest
  ): Observable<GetCatalogItemResponse | ErrorResponse> {
    let path: string = `api/catalog/private/type`;
    return this.apiService.get(path, params);
  }

  /**
   * Service to get catalog types
   * @param params GetCatalogTypeRequest
   * @returns Observable<GetCatalogTypeResponse | ErrorResponse>
   */
  getCatalogTypes(
    params: GetCatalogTypeRequest
  ): Observable<GetCatalogTypeResponse | ErrorResponse> {
    let path: string = `api/catalog/private/type`;
    return this.apiService.get(path, params);
  }

  add(params: AddCatalogItemRequest): Observable<AddServiceStandarResponse> {
    let path: string = `api/catalog/private/item`;
    return this.apiService.post(
      path,
      params
    ) as Observable<AddServiceStandarResponse>;
  }

  addCatalogItemType(
    params: AddCatalogItemTypeRequest
  ): Observable<AddServiceStandarResponse> {
    let path: string = `api/catalog/private/type`;
    return this.apiService.post(
      path,
      params
    ) as Observable<AddServiceStandarResponse>;
  }

  edit(params: EditCatalogItemRequest): Observable<EditServiceStandarResponse> {
    let path: string = `api/catalog/private/item/${params.itemId}`;
    delete params.itemId;
    return this.apiService.put(
      path,
      params
    ) as Observable<EditServiceStandarResponse>;
  }

  editCatalogItemType(
    params: EditCatalogItemTypeRequest
  ): Observable<EditServiceStandarResponse> {
    let path: string = `api/catalog/private/type/${params.typeId}`;
    delete params.typeId;
    return this.apiService.put(
      path,
      params
    ) as Observable<EditServiceStandarResponse>;
  }

  /**
   * Service to get days of week
   * @returns any
   */
  getCatalogWeekDays(): any {
    let weekDays: any = [
      { value: 1, text: 'Lunes' },
      { value: 2, text: 'Martes' },
      { value: 3, text: 'Miercoles' },
      { value: 4, text: 'Jueves' },
      { value: 5, text: 'Viernes' },
      { value: 6, text: 'Sábado' },
      { value: 0, text: 'Domingo' },
    ];

    return weekDays;
  }

  /**
   * Set selected catalog item
   * @param data item selected
   * @returns void
   */
  setSelected(data: CatalogItem): void {
    this.selected = data;
  }

  /**
   * Set selected catalog item type
   * @param data item selected
   * @returns void
   */
  setSelectedType(data: CatalogItemType): void {
    this.selectedType = data;
  }

  /**
   * Get selected catalog item
   * @returns void
   */
  getSelected(): CatalogItem {
    return this.selected;
  }

  /**
   * Get selected catalog item type
   * @returns void
   */
  getSelectedType(): CatalogItemType {
    return this.selectedType;
  }

  /**
   * Set operation item
   * @param data item selected
   * @returns void
   */
  setOperation(data: string): void {
    this.operation = data;
  }

  /**
   * Get operation item
   * @returns string
   */
  getOperation(): string {
    return this.operation;
  }

  /**
   * Set dropdowns items
   * @param data item selected
   * @returns void
   */
  setDropdowns(data: any): void {
    this.dropdowns = data;
  }

  /**
   * Get dropdowns items
   * @returns void
   */
  getDropdowns(): any {
    return this.dropdowns;
  }

  /**
   * Service to delete catalog item
   * @param params DeleteCatalogItemRequest
   * @returns Observable<GetCatalogItemResponse | ErrorResponse>
   */
  deleteCatalogItem(
    params: DeleteCatalogItemRequest
  ): Observable<DeleteCatalogItemResponse | ErrorResponse> {
    let path: string = `api/catalog/private/item/${params.itemId}`;
    return this.apiService.delete(path);
  }

  /**
   * Service to delete catalog item type
   * @param params DeleteCatalogItemTypeRequest
   * @returns Observable<GetCatalogItemTypeResponse | ErrorResponse>
   */
  deleteCatalogItemType(
    params: DeleteCatalogItemTypeRequest
  ): Observable<DeleteCatalogItemTypeResponse | ErrorResponse> {
    let path: string = `api/catalog/private/type/${params.typeId}`;
    return this.apiService.delete(path);
  }
}
