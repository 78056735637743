
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.scss']
})

export class IconsComponent implements OnInit {
    /*
     * output for icons selected
     */
    @Output() iconsSelectedHandler = new EventEmitter();
    /*
     * indicate the icons selected
     */

    icons = [
        'icon-pie-chart',
        'icon-rocket',
        'icon-user',
        'icon-support',
        'icon-pencil',
        'icon-speech',
        'icon-graph',
        'icon-pointer',
'icon-screen-desktop',
'icon-plane',
'icon-notebook',
'icon-magnet',
'icon-mouse',
'icon-energy',
'icon-emoticon-smile',
'icon-disc',
'icon-social-youtube',
'icon-social-facebook',
'icon-social-twitter',
'icon-social-tumblr',
'icon-social-dribbble',
'icon-screen-tablet',
'icon-game-controller',
'icon-envelope-open',
'icon-fire',
'icon-envelope-letter',
'icon-speech',
'icon-wallet',
'icon-printer',
'icon-picture',
'icon-pin',
'icon-map',
'icon-globe',
'icon-folder-alt',
'icon-direction',
'icon-docs',
'icon-call-end',
'icon-compass',
'icon-basket',
'icon-home',
'icon-bar-chart',
'icon-envelope',
    ];

    selected = 'icon-pie-chart';
    constructor(private modalService: NgbModal) { }

    ngOnInit() {
        this.iconsSelectedHandler.emit('icon-pie-chart');
    }
    /*
     * emit the icons selected
     */
    emitIcon(event) {
        this.selected = event;
        this.iconsSelectedHandler.emit(event);
    }

}
