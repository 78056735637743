
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';



@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})

export class TimelineComponent implements OnInit {


    constructor() { }

    ngOnInit() {

    }


}
