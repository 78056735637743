import * as _ from 'lodash';
export class LocalSorter {

  protected static COMPARE = (direction: any, a: any, b: any) => {
    if (a < b) {
      return -1 * direction;
    }
    if (a > b) {
      return direction;
    }
    return 0;
  }

  static sort(data: Array<any>, field: string, direction: string, customCompare?: Function): Array<any> {
    const dir: number = (direction === 'asc') ? 1 : -1;
    const compare: Function = customCompare ? customCompare : this.COMPARE;
    return data.sort((a, b) => {
      return compare.call(null, dir, this.convertPathToArrayOfItems(field.split('.'), a), this.convertPathToArrayOfItems(field.split('.'), b));
    });
  }

  /**
  * Recursive function that obtain the array of items.
  * param arrPath Path splited
  * param row Row to check the path.
  * returns {string |string[]} Return the list of items to show.
  */
 static convertPathToArrayOfItems(arrPath: string[], row: any): string | string[] {
  let partOfItem: any = _.get(row, arrPath.shift(), undefined);
  if (Array.isArray(partOfItem)) {
    let arrReturn: string[] = [];
    partOfItem.forEach((arrItem) => {
      if (arrPath.length === 0) {
        arrReturn.push(arrItem);
      } else {
        let returnedItem: string | string[] = this.convertPathToArrayOfItems(arrPath.slice(), arrItem);
        if (Array.isArray(returnedItem)) {
          console.warn('COL-TEXT: Only support one array of objects in the paths.')
        } else {
          arrReturn.push(returnedItem);
        }
      }
    });
    return arrReturn;
  } else {
    if (arrPath.length === 0) {
      return partOfItem;
    } else {
      return this.convertPathToArrayOfItems(arrPath, partOfItem);
    }
  }
}
}



