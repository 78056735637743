import { NgModule, Optional, SkipSelf } from "@angular/core";
import { StorageService } from "./services/storage.service";
import { SharedDataService } from "./services/shared-data.service";
import { AuthGuard } from "./guards/authorizated.guard";
import { ApiService, CatalogService, EmailService, LoaderService, ProfileService, ToastrService, LanguagesService } from "./services";
import { OriginsService } from "./services/origins.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    StorageService,
    SharedDataService,
    AuthGuard,
    ApiService,
    CatalogService,
    EmailService,
    LoaderService,
    ProfileService,
    ToastrService,
    LanguagesService,
    OriginsService,
  ],
  bootstrap: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only");
    }
  }
}
