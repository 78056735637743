
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface IButton {
    id: string;
    type: string;
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {
    /*
    * data for config the button
    */
    @Input() dataButton: IButton;
    /*
     *data for config the button
    */
    @Input() type = 'primary';
    /*
    * id button for emit
    */
    @Output() buttonHandler = new EventEmitter();

    constructor() { }
    ngOnInit() {
    
    }

    emitButton() {
        if (this.dataButton) {
            this.buttonHandler.emit(this.dataButton.id);
        }

    }

}