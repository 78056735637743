// External Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorResponse } from '../../shared/interfaces';
import {
  AddAdminOriginRequest,
  AddAdminOriginResponse,
  AdminOrigin,
  DeleteAdminOriginRequest,
  DeleteAdminOriginResponse,
  EditAdminOriginOrderRequest,
  EditAdminOriginOrderResponse,
  EditAdminOriginRequest,
  EditAdminOriginResponse,
  GetAdminOriginsRequest,
  GetAdminOriginsResponse,
} from '../interfaces/origins.interfaces';
import { ApiService } from './api.service';

// Internal dependencies
@Injectable()
export class OriginsService {

  /**
   * Selected origin
   */
  private selected: AdminOrigin;

  /**
   * Selected operation add or edit
   */
  private operation: string;

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Service to get list of administration origins
   * @param params GetAdminOriginsRequest
   * @returns Observable<GetAdminOriginsResponse | ErrorResponse>
   */
  getInitialData(params?: GetAdminOriginsRequest): Observable<GetAdminOriginsResponse | ErrorResponse> {
    let path: string = `api/admin/private/origin`;
    return this.apiService.get(path, params);
  }

  /**
   * Service to order administration origin
   * @param params EditAdminOriginOrderRequest
   * @returns Observable<EditAdminOriginOrderRequest | ErrorResponse>
   */
  orderData(params: EditAdminOriginOrderRequest): Observable<EditAdminOriginOrderResponse | ErrorResponse> {
    let path: string = `api/admin/private/origin/${params.originId}`;
    delete params.originId;
    return this.apiService.put(path, params);
  }

  /**
   * Service to delete a administration origin
   * @param params DeleteAdminOriginRequest
   * @returns Observable<DeleteAdminOriginResponse | ErrorResponse>
   */
  delete(params: DeleteAdminOriginRequest): Observable<DeleteAdminOriginResponse | ErrorResponse> {
    let path: string = `api/admin/private/origin/${params.originId}`;
    return this.apiService.delete(path);
  }

  /**
   * Service to edit a administration origin
   * @param params EditAdminOriginRequest
   * @returns Observable<EditAdminOriginResponse | ErrorResponse>
   */
  edit(params: EditAdminOriginRequest): Observable<EditAdminOriginResponse | ErrorResponse> {
    let path: string = `api/admin/private/origin/${params.originId}`;
    delete params.originId;
    return this.apiService.put(path, params);
  }

  /**
   * Service to add a administration origin
   * @param params AddAdminOriginRequest
   * @returns Observable<AddAdminOriginResponse | ErrorResponse>
   */
  add(params: AddAdminOriginRequest): Observable<AddAdminOriginResponse | ErrorResponse> {
    let path: string = `api/admin/private/origin`;
    return this.apiService.post(path, params);
  }

  /**
   * Set selected item
   * @param data item selected
   * @returns void
   */
  setSelected(data: AdminOrigin): void {
    this.selected = data;
  }

  /**
   * Get selected item
   * @returns AdminOrigin
   */
  getSelected(): AdminOrigin {
    return this.selected;
  }

  /**
   * Set operation item
   * @param data item selected
   * @returns void
   */
  setOperation(data: string): void {
    this.operation = data;
  }

  /**
   * Get operation item
   * @returns string
   */
  getOperation(): string {
    return this.operation;
  }

}
